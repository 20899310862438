<template>
    <div class="home-container">
        <div class="mask" v-if="isLoading && video_login == 1 && !token">
            <van-loading type="spinner" size="80px" />
        </div>
        <div class="home-scroll">
            <!-- 4个列表 -->
            <div class="fourtabs_box" style="margin-top: 20px; position: fixed; top: 0; left: 0;">
                <div v-for="(tab, index) in tabs" :key="index"
                    :class="{ 'tab_item': selectedTab !== index, 'tab_item_sel': selectedTab === index }"
                    @click="selectTab(index)">
                    {{ tab }}
                </div>
            </div>


            <div class="listcontent_box" style="margin-top: 70px;">
                <!-- 1.产品列表 -->
                <!-- 1.产品列表 -->
                <div class="productlist_box" v-if="this.selectedTab == 0">
                    <div class="product_item" v-for="(item, index) in productList" :key="index">
                        <div class="changxiao_txt">{{ $t('畅销') }}</div>
                        <p class="pro_id">ID:{{ item.id }}</p>

                        <img :src="item.img_url" align="right" width="120" style="border-radius: 100% ;" height="120"
                            hspace="5" vspace="5" />
                        <p class="pro_info">{{ $t('药品') }}:: {{ item.xuanfei_name }}</p>
                        <p class="pro_info">{{ $t('药品详情') }}:: {{ item.content }}</p>
                    </div>
                </div>
                <!-- 2.实时下单 -->
                <div class="orderList_box" ref="orderList" v-if="this.selectedTab == 1">
                    <div class="order_item" v-for="(item, index) in winners" :key="index">
                        <div class="order_info">{{ item }}</div>
                        <div class="order_status"></div>
                    </div>
                </div>
                <!-- 3.用户反馈 -->
                <div class="fankuiList_box" ref="fankuiList" v-if="this.selectedTab == 2">
                    <div class="fankui_item" v-for="(item, index) in feedbacklist" :key="index" >
                        <div class="userinfo_box" style="display: flex; align-items: center;">
                            <img :src="item.head_img" style="height: 30px; width: 30px; border-radius: 100%;" alt="">
                            <div class="fankui_info" style="padding-left: 20px;">{{ item.nickname }}</div>
                        </div>
                        <div class="fankui_status">{{ item.content }}</div>
                    </div>
                </div>
                <!-- 4. 服务条款 -->
                <div class="fuwu_box" v-if="this.selectedTab == 3">

                    <div class="fuwu_content">{{ about }}</div>

                </div>
            </div>

        </div>
        <img class="kefu" v-if="iskefu == '1'" src="@/assets/kefu.png" @click="toKefu" alt="" srcset="">
        <van-popup v-model="pop_content" round closeable close-icon="close"
            style="width:70%;height: 50%;font-size: 24px;color: #000;">
            <div class="pop_content-content" v-html="pop_content_txt"></div>


        </van-popup>
    </div>
</template>

<script>
export default {
    data() {
        return {
            notice: this.$t("index.loading"),
            banners: [{}],
            basicData: [],
            gameitem: [{}, {}, {}, {}],
            movielist_0: [{}, {}, {}, {}],
            movielist_1: [],
            isLoading: true,
            movielistSwiperOption: {
                slidesPerView: 'auto',
                spaceBetween: 0,
                slidesPerGroup: 1,
            },
            bannerSwiperOption: {
                effect: 'coverflow',
                grabCursor: true,
                centeredSlides: true,
                slidesPerView: 'auto',
                speed: 800,
                autoplay: true,
                coverflowEffect: {
                    rotate: 50,
                    stretch: 10,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true
                }
            },
            video_login: this.$store.getters.getBaseInfo.video_login || 1,
            token: localStorage.getItem('token'),
            pop_content_txt: '',
            pop_content: false,
            kefu: '',
            iskefu: 0,
            tabs: [
                this.$t('产品'),
                this.$t('实时下单'),
                this.$t('用户反馈'),
                this.$t('服务条款')
            ],
            productList: [],
            selectedTab: 0, // 默认选中第一个
            scrollTimer: null,
            scrollTimerfankui: null,
            xuanfei: null,
            winners: [],
            about: null,
            feedbacklist: []

        };
    },
    methods: {
        selectTab(index) {
            this.selectedTab = index;
            this.stopAutoScroll();
            this.stopAutoScrollfanlui();
            if (index == 1) {
                this.$nextTick(() => {
                    this.startAutoScroll();
                });
            }
            if (index == 2) {
                console.log('222222');

                this.$nextTick(() => {
                    this.startAutoScrollFankui();
                });
            }
        },
        toKefu() {

            window.location.href = this.kefu
        },

        gotoMenu(router) {
            this.$router.replace(router)
        },
        toLottery(key, id) {
            if (!localStorage.getItem('token') && this.$store.getters.getBaseInfo.video_login == 1) {
                this.$router.push({ path: '/Login' })
            } else {
                if (id) {
                    this.$router.push({ path: '/Lottery?key=' + key + "&id=" + id })
                }
            }

        },
        toPlayVideo(id) {

            if (!localStorage.getItem('token') && this.$store.getters.getBaseInfo.video_login == 1) {
                this.$router.push({ path: '/Login' })
            } else {
                if (id) {
                    this.$router.push({ path: '/PlayVideo?id=' + id })
                }
            }
        },
        onRefresh() {
            setTimeout(() => {
                this.getBasicConfig();
                this.isLoading = false;
                this.$toast(this.$t('reservation.refresh'));
            }, 500);
        },
        getBasicConfig() {
            this.$http({
                method: 'get',
                url: 'sys_config'
            }).then(res => {
                this.basicData = res.data;


                this.$nextTick(() => {
                    this.lazyLoadImg()
                })
            })

        },
        getXuanfeiList() {
            this.$http({
                method: 'get',
                url: 'xuanfeilist'
            }).then(res => {
                this.productList = res.data
            })
        },
        getNotice(data) {
            this.notice = data.notice || '';
        },
        getGameItem() {
            this.$http({
                method: 'get',
                url: 'lottery_hot'
            }).then(res => {
                this.gameitem = res.data
            })
        },
        getMovieList_0(data) {
            this.movielist_0 = data.movielist_0
        },
        getMovieList_1(data) {
            this.movielist_1 = data.movielist_1
        },
        getBanner(data) {
            this.banners = data.banners;
        },
        query(selector) {
            return Array.from(document.querySelectorAll(selector));
        },
        lazyLoadImg() {
            if (this.movielist_1.length > 0) {
                this.query("img[data-src]").forEach((img) => {
                    const rect = img.getBoundingClientRect();
                    if (rect.top < window.innerHeight) {
                        img.src = img.dataset.src;
                        // 我们是通过img[data-src]查找所有img标签的，渲染后就删除data-src可减少forEach循环的计算成本
                        img.removeAttribute("data-src");
                    }
                });
            }
        },
        startAutoScroll() {
            const orderList = this.$refs.orderList;
            if (!orderList) {
                console.error('orderList is not defined');
                return;
            }
            const itemHeight = orderList.querySelector('.order_item')?.offsetHeight;
            if (!itemHeight) {
                console.error('order_item is not found');
                return;
            }
            let scrollPosition = 0;
            const scrollInterval = 1000; // 每2秒滚动一次

            const scrollFunction = () => {
                scrollPosition += itemHeight;
                if (scrollPosition >= orderList.scrollHeight) {
                    scrollPosition = 0;
                }
                orderList.scrollTo({
                    top: scrollPosition,
                    behavior: 'smooth'
                });
                this.scrollTimer = setTimeout(scrollFunction, scrollInterval);
            };

            this.scrollTimer = setTimeout(scrollFunction, scrollInterval);
        },

        stopAutoScroll() {
            if (this.scrollTimer) {
                clearTimeout(this.scrollTimer);
                this.scrollTimer = null;
            }
        },
        startAutoScrollFankui() {
            const fankuiList = this.$refs.fankuiList;
            if (!fankuiList) {
                console.error('fankuiList is not defined');
                return;
            }
            const itemHeight = fankuiList.querySelector('.fankui_item')?.offsetHeight;
            if (!itemHeight) {
                console.error('fankui_item is not found');
                return;
            }
            let scrollPosition = 0;
            const scrollInterval = 1000; // 每2秒滚动一次

            const scrollFunction = () => {
                scrollPosition += itemHeight;
                if (scrollPosition >= fankuiList.scrollHeight) {
                    scrollPosition = 0;
                    fankuiList.scrollTop = 0; // 立即回到顶部

                } else {
                    fankuiList.scrollTo({
                        top: scrollPosition,
                        behavior: 'smooth'
                    });
                }

                this.scrollTimerfankui = setTimeout(scrollFunction, scrollInterval);
            };

            this.scrollTimerfankui = setTimeout(scrollFunction, scrollInterval);
        },
        stopAutoScrollfanlui() {
            if (this.scrollTimerfankui) {
                clearTimeout(this.scrollTimerfankui);
                this.scrollTimerfankui = null;
            }
        }
    },
    beforeDestroy() {
        this.stopAutoScroll();
        this.stopAutoScrollfanlui();
    },
    mounted() {
        let that = this;
        window.addEventListener("scroll", () => {
            // 这里做一个 节流 操作
            that.lazyLoadImg()
        });

    },
    created() {
        this.$http({
            method: 'get',
            url: 'base_info'
        }).then(res => {
            if (res.code == 200) {

                if (!localStorage.getItem('token') && res.data.video_login == 1) {
                    return this.$router.push({ path: '/Login' })
                }
                console.log(res)
                this.isLoading = false
                this.pop_content_txt = res.data.pop_content
                this.kefu = res.data.kefu
                this.iskefu = res.data.iskefu
                this.winners = res.data.winners
                this.about = res.data.about
                this.feedbacklist = res.data.feedbacklist
                if (res.data.ispop == 1) {
                    this.pop_content = true
                }


                this.$store.commit('setBaseInfoValue', res.data);
                this.getXuanfeiList();//获取首页游戏列表
            }
        })
    }
}

</script>

<style lang='less' scoped>
@notice-bar-size: 30px;
@movie-list-item-bottom-size: 25px;

.linear-bg {
    height: 200px;
    background: linear-gradient(270deg, #2c2b29, #1c1e23);
}

.home-container {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgb(44, 43, 41);
    min-height: 100vh;
}

.welcome_box {
    margin: 0 12px;
    margin-top: 40px;

}

.title {
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFCC99;
    line-height: 1;
    font-size: 28px;
}

.username {
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFCC99;
    line-height: 1;
    font-size: 36px;
    margin-top: 20px;
}

.ins_box {
    margin: 0 12px;
    border: 1px solid #766350;
    margin-top: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    .app_name {
        color: #f3c7a2;
        font-weight: 700;
        text-align: center;
        min-width: 200px;
        font-size: 32px;
    }

    .infos_box {
        .ins_txt {
            font-size: 20px;
            color: white;
        }

        .ins_lable {
            color: #f3c7a2;
            font-size: 20px;
            margin-top: 80px;
        }

    }
}

.fourtabs_box {
    display: flex;
    gap: 15px;
    margin: 0 12px;
    margin-top: 20px;
    width: 100%;
    justify-content: space-around;

    .tab_item {
        font-size: 25px;
        color: white;
        background: linear-gradient(90deg, #5b606c, #2b2b2b);
        border-radius: 24px;
        padding: 5px 10px;
    }

    .tab_item_sel {
        font-size: 25px;
        color: #8a5623;
        background: linear-gradient(#fde3ca, #e7b486);
        border-radius: 24px;
        padding: 5px 10px;
    }
}

.productlist_box {
    margin: 0 12px;

    .product_item {
        width: 100%;
        border: 1px solid #766350;
        padding: 12px;
        padding-bottom: 60px;
        margin-top: 20px;
        border-radius: 8px;
        // display: flex;
        position: relative;
        background: linear-gradient(120deg, #212121, #313131);
        /* 隐藏超出部分 */
        overflow: hidden;

        .changxiao_txt {
            position: absolute;
            right: -1.125rem;
            top: 0.5625rem;
            background: #ff6056;
            font-size: 0.75rem;
            padding: 0.0625rem 1.25rem;
            font-style: normal;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            color: #fff;

        }

        .pro_id {
            background: linear-gradient(90deg, #efcba7, #dcab76);
            padding: 10px 20px;
            color: #8d5825;
            font-size: 22px;
            width: auto;
            display: inline-block;
            /* 使宽度自适应 */
            border-radius: 24px;
        }

        .pro_title {
            color: white;
            font-size: 28px;
            margin-left: 20px;
            margin-top: 20px;
            line-height: 45px;
        }

        .pro_info {
            color: white;
            font-size: 28px;
            margin-left: 20px;
            line-height: 45px;
        }


    }

}

.orderList_box {
    margin: 0 12px;
    margin-top: 20px;
    border: 1px solid #766350;
    padding: 0 12px;
    height: 700px;
    /* 4个item的高度, 这里假设每个item高度为 26px(字体) + 20px + 10px(间距) + 12px(上下padding) */
    overflow-y: auto;
    border-radius: 8px;
    background: linear-gradient(120deg, #212121, #313131);

    .order_item {
        padding: 12px 0;
        /* item之间的间距 */
    }

    .order_info {
        color: #e6b98a;
        font-size: 26px;
    }

    .order_status {
        color: white;
        font-size: 20px;
        margin-top: 10px;
    }
}

.fankuiList_box {
    margin: 0 12px;
    margin-top: 20px;
    border: 1px solid #766350;
    padding: 0 12px;
    height: 700px;
    /* 4个item的高度, 这里假设每个item高度为 26px(字体) + 20px + 10px(间距) + 12px(上下padding) */
    overflow-y: auto;
    border-radius: 8px;
    background: linear-gradient(120deg, #212121, #313131);

    .fankui_item {
        padding: 12px 0;
        /* item之间的间距 */
    }

    .fankui_info {
        color: #e6b98a;
        font-size: 26px;
    }

    .fankui_status {
        color: white;
        font-size: 20px;
        margin-top: 10px;
    }
}


.fuwu_box {
    margin: 0 12px;
    margin-top: 20px;
    border: 1px solid #766350;
    padding: 50px 25px;
    border-radius: 8px;
    background: linear-gradient(120deg, #212121, #313131);
    height: 700px;
    overflow: hidden;
    overflow-y: scroll;

    .fuwu_title {
        color: #f3c7a2;
        font-weight: 700;
        font-size: 26px;
    }

    .fuwu_content {
        color: white;
        font-size: 25px;
        line-height: 45px;
    }
}




.linear-gradient {
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));
}

::v-deep .van-notice-bar__left-icon,
.van-notice-bar__right-icon {
    min-width: 40px;
}

.notice-swipe {
    width: calc(100% - 50px);
    height: 85px;
    font-size: @notice-bar-size;
}

::v-deep .van-icon-bullhorn-o::before {
    transform: scale(2.5);
}

.banner {
    width: 100%;
    margin-top: -23%;
}

.banner_swiper {
    height: 100%;
    width: 100%;

    .swiper-slide {
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 620px;
        height: 300px;
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        background-color: #ffffff;
        background-position: center;
        background-size: cover;
        color: #ffffff;
    }
}

::v-deep .swiper-container-3d .swiper-slide-shadow-left {
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

::v-deep .swiper-container-3d .swiper-slide-shadow-right {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

.banner_img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
}

.hot-game {
    width: 100%;
    height: 100%;
}

.hot-title-div {
    width: calc(100% - 50px);
    margin: 0 auto;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hot-title-div>div:first-child {
    width: 430px;
}

.hot-title-div div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.hot-title-div>div:nth-child(2) span {
    font-size: 20px;
    color: #979799;
}

.hot-title-div>div:first-child span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 10px;
    font-weight: 700;
    color: @theme-color-dark;
}

.hot-title-div>div:first-child span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 28px;
    font-weight: 700;
    color: @theme-color-dark;
}

.hot-title-div>div:nth-child(2) span {
    font-size: 25px;
    color: #979799;
}

.hot-title-div>div:first-child span:before {
    content: "";
    display: block;
    width: 5px;
    height: 30px;
    background-color: #1c1e23;
    border-radius: 1px;
    margin-right: 5px;
}

.hot-game .hot-items-div {
    margin-top: -3px;
}

.hot-game .hot-items-div span {
    margin-top: 10px;
    font-size: 28px;
    color: @theme-color-dark;
}

.hot-recommend {
    width: 100%;
    flex: 1;
    background-color: #f2f2f5;
}

.movie_swiper {
    .swiper-slide {
        width: 80%;
    }
}

.movie_list_0 {
    width: calc(100% - 50px);
    margin: 0 auto;
}

.movie_cover {
    border-radius: 10px;
    width: 550px;
    height: 330px
}

.movie_list_0 .movie-list-item-bottom {
    position: relative;
    width: 550px;
    bottom: 43px;
}

.movie_list_0 .movie-list-item-bottom .movie-time-div {
    background-color: rgba(0, 0, 0, .4);
}

.movie_list_0 .movie-list-item-bottom>div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
    font-size: 28px;
    color: #fff;
}

.movie_list_0 .movie-time-div {
    color: #fff;
    border-radius: 0 0 10px 10px;
}

.movie_list_0 .movie_swiper .hot {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 80px;
}

.movie_list_0 span {
    font-size: 30px;
}

.movie_list_1 {
    display: flex;
    width: calc(100% - 50px);
    margin: 0 auto;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.movie_list_1 .movie-list-item .cover_img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
}

.home-scroll {
    padding-bottom: 110px;
}

.movie_list_1 .movie-list-item {
    margin-bottom: 10px;
    width: 335px;
    height: 290px;
}

.movie_list_1 .movie-list-item-bottom {
    position: relative;
    width: 335px;
    bottom: 42px;

}

.movie_list_1 .movie-list-item:nth-child(odd) {
    margin-right: 10px;
}

.movie_list_1 .movie-list-item-bottom .movie-time-div {
    background-color: rgba(0, 0, 0, .4);
}

.movie_list_1 .movie-list-item-bottom>div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
    color: #fff;
}

.movie_list_1 .movie-time-div {
    color: #fff;
    border-radius: 0 0 10px 10px;
    height: 35px;
}

.movie_list_1 .movie_swiper .hot {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 5px;
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
    overflow: hidden;
    white-space: nowrap;
    width: 180px;
    padding-left: 8px;
    font-size: 25px;
}

.movie_list_1 .movie-list-item .movie-time-div span:last-child {
    overflow: hidden;
    white-space: nowrap;
    width: 0px;
    padding-right: 110px;
    font-size: 22px;
}

.movie_list_0 .movie-time-div span:first-child {
    overflow: hidden;
    white-space: nowrap;
    width: 350px;
    padding-left: 10px;
    font-size: 25px;
}

.hot-recommend-more {
    width: 100%;
    padding-bottom: 20px;
    text-align: center;
    color: #979799;
    font-size: 30px;
}

.hot-items-div .game_item_img {
    width: 100px;
    height: 100px;
}

::v-deep .hot-items-div .game_item_img .van-image__img {
    border-radius: 20px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
    color: @theme-color-dark;
    font-size: 35px;
}

.mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.kefu {
    position: fixed;
    bottom: 300px;
    right: 60px;
    width: 80px;
    height: 80px;
    z-index: 999;
    // background: #fff;border-radius: 50px;
}

/deep/.van-popup__close-icon {
    font-size: 50px;
}

.pop_content-content {
    font-size: 30px;
    color: #1c1e23;
    padding: 30px;
    margin-top: 40px;
}
</style>