<template>
  <div class="convention-hall page" style="background: black;">
    <van-nav-bar class="nav-bar" :title="$t('热门选购')" />

    <!-- 导航 -->
    <div class="nav_box" @click="gotoMenu('/Video')">
      <div class="nav_left_box">
        <img class="game_03" src="../../assets/game_03.png" alt="">
        <div class="nav_title">{{ $t('会员视频') }}</div>
      </div>
      <div class="nav_right_box">
        <div class="nav_lable">{{ $t('海量视频免费观看') }}！</div>
        <div class="icon_box">
          <div class="dian_box">

          </div>
          <img class="game_01" src="../../assets/game_01.png" alt="">
        </div>
        <van-icon name="arrow" />
      </div>
    </div>

    <div class="nav_box" @click="gotoMenu('/Home')">
      <div class="nav_left_box">
        <img class="game_03" src="../../assets/game_04.png" alt="">
        <div class="nav_title">{{ $t('新人福利') }}</div>
      </div>
      <div class="nav_right_box">
        <div class="nav_lable">{{ $t('新加入') }}  ID: 087***</div>
        <div class="icon_box">
          <div class="dian_box">
          </div>
          <img class="game_01" src="../../assets/game_02.png" alt="">
        </div>
        <van-icon name="arrow" />
      </div>
    </div>
    <div class="nav_box">
      <div class="nav_left_box">
        <img class="game_03" src="../../assets/game_05.png" alt="">
        <div class="nav_title">{{ $t('平台防伪标识') }}</div>
      </div>
      <div class="nav_right_box">
      </div>
    </div>


    <!-- 游戏列表 -->
    <div class="gamelist_box" v-if="gameitem">
      <div class="game_item" v-for="(v, key) in gameitem" :key="key" @click="toLottery(v.key, v.id)">
        <div class="game_name">{{ v.name }}</div>
        <div class="game_img">
          <van-image class="game_item_img" :src="v.ico">
            <template v-slot:loading>
              <van-loading type="circular" />
            </template>
          </van-image>
        </div>
      </div>
    </div>



    <div class="convention-item" v-if="false">
      <div class="left">
        <van-sidebar @change="onChange" v-model="activeKey">
          <van-sidebar-item :title="$t('index.all')" />
          <van-sidebar-item v-for="(v, key) in lotteryitem" :key="key" :title="v.name" />
        </van-sidebar>
      </div>
      <div class="right">
        <van-pull-refresh :border="false" class="list-wrapper" v-model="isLoading" @refresh="onRefresh"
          :pulling-text="$t('refresh.pulling')" :loosing-text="$t('refresh.loosing')"
          :loading-text="$t('refresh.loading')">
          <van-grid :column-num="2">
            <van-grid-item @click="toLottery(v.key, v.id)" v-for="(v, key) in gameitem" :key="key">
              <van-image class="game_item_img" :src="v.ico">
                <template v-slot:loading>
                  <van-loading type="circular" />
                </template>
              </van-image>
              <span>{{ v.name }}</span>
              <span>{{ v.desc }}</span>
            </van-grid-item>
          </van-grid>
        </van-pull-refresh>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      gameitem: [{}, {}, {}, {}],
      lotteryitem: [{}, {}, {}, {}],
      isLoading: false,
      activeKey: 0,
    };
  },
  methods: {
    gotoMenu(router) {
      this.$router.push(router)
    },
    onRefresh() {
      setTimeout(() => {
        Toast(this.$t("reservation.refresh"));
        this.isLoading = false;
      }, 500);
    },
    toLottery(key, id) {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' })
      } else {
        this.$router.push({ path: '/Lottery?key=' + key + "&id=" + id })
      }
    },
    getGameItem() {
      this.$http({
        method: 'get',
        url: 'lottery_list'
      }).then(res => {
        this.gameitem = res.data;
      })
    },
    onChange(index) {
      this.$http({
        method: 'get',
        data: { class: index },
        url: 'lottery_list'
      }).then(res => {
        this.gameitem = res.data;
      })
    },
    getLotteryItem() {
      this.$http({
        method: 'get',
        url: 'lottery_class'
      }).then(res => {
        this.lotteryitem = res.data;
      })
    }
  },
  created() {
    this.getGameItem();//获取首页游戏列表
    this.getLotteryItem();
  }
};
</script>

<style lang='less' scoped>
.gamelist_box{
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  background: linear-gradient(120deg, #212121, #313131);
  padding: 40px 12px;
  margin-top: 40px;
  .game_item{
    width: calc(50% - 20px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 20px;
    background: rgb(33, 33, 33);
    border-radius: 24px;

    .game_name{
      color: #f3c7a2;
      font-size: 32px;
      max-width: 120px;
      word-wrap: break-word; /* 强制换行 */
  overflow-wrap: break-word; /* 支持较新的浏览器 */

    }
    .game_img{
      .game_item_img{
        width: 90px;

      }
    }

  }
}
.nav_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 12px;
  background: rgb(22, 21, 27);
  border-bottom: 0.0625rem solid #16151b;

  .nav_left_box {
    display: flex;
    align-items: center;

    .game_03 {
      height: 50px;
    }

    .nav_title {
      padding-left: 20px;
      font-size: 32px;
    }
  }

  .nav_right_box {
    display: flex;
    align-items: center;

    .icon_box {
      height: 80px;
      position: relative;

      .dian_box {
        background: red;
        height: 10px;
        width: 10px;
        border-radius: 50px;
        position: absolute;
        top: 0;
        right: 0;


      }

      .game_01 {
        height: 80px;
        border-radius: 100%;
      }
    }

    .nav_lable {
      color: rgb(134, 134, 134);
      font-size: 24px;
    }

  }

}

.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}

.nav-bar {
  background: linear-gradient(90deg, #1c1e23, #2E2D2B);
  height: 100px;

}

.van-nav-bar {
  line-height: 50px;
}

::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35px;
}

::v-deep .van-nav-bar__content {
  height: 100px;
}

.van-sidebar {
  width: 180px;
}

.van-sidebar-item--select::before {
  left: 10px;
  height: 44%;
  background-color: #1c1e23;
  border-radius: 5px;
  width: 10px;
}

.van-sidebar-item--select {
  color: #1c1e23;
  font-size: 35px;
  text-align: center;
}

/deep/ .van-sidebar-item__text {
  width: 140px;
  margin-left: -25px;
}

.van-sidebar-item {
  font-size: 30px;
  text-align: center;
  padding: 50px;
  background-color: #ffffff;
}

.van-sidebar-item--select,
.van-sidebar-item--select:active {
  background-color: #f2f2f5;
  ;
}

.convention-item {
  display: flex;
  align-items: center;
  height: calc(100% - 15px);
}

.convention-hall {
  display: flex;
  flex-direction: column;
  bottom: 20px;
  background: #f2f2f5;
}

.convention-item .left {
  height: 100%;
  background-color: #fff;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.convention-item .right {
  height: 100%;
  flex: 1;
  background-color: #f2f2f5;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.convention-item .right .list-wrapper {
  padding: 20px 20px;
  min-height: 800px;
}

.convention-item .right .list-wrapper .game_item_img {
  width: 200px;
  height: 200px;
}

.convention-item .right .list-wrapper span {
  margin-top: 10px;
  font-size: 30px;
  color: @theme-color-dark;
}

.convention-item .right .list-wrapper span:last-child {
  margin-top: 10px;
  font-size: 24px;
  color: @theme-color-dark;
}

.van-grid-item {
  padding: 10px;

}

::v-deep .van-grid-item__content--center {
  border-radius: 15px;
}

::v-deep .van-image__img {
  border-radius: 40px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: @theme-color-dark;
  font-size: 35px;
}
</style>
