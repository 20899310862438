<template>
  <van-tabbar
    v-if="show"
    v-model="active"
    active-color="#7e5678"
    :border="true"
    inactive-color="#979799"
  >
  <!-- 首页 -->
    <van-tabbar-item to="/girls">
      <span class="foorterTxt">{{ $t("商品展示") }}</span>
      <template #icon="props">
        <img
           :src="props.active ? '/img/footer/tab_01_sel.png?d='+new Date()*1 : '/img/footer/tab_01.png?d='+new Date()*1"
          :alt="$t('商品展示')"
          v-show="show !== 0"
        />
        <img
          :src="props.active ? '/img/footer/tab_01_sel.png?d='+new Date()*1 : '/img/footer/tab_01.png?d='+new Date()*1"
          :alt="$t('商品展示')"
          :class="$t('foorter.index')"
          style="height: 4rem"
          v-show="show === 0"
        />
      </template>
    </van-tabbar-item>
    <!-- 预约 -->
    <van-tabbar-item to="/Game">
      <span class="foorterTxt">{{ $t("热门选购") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/tab_02_sel.png?d='+new Date()*1 : '/img/footer/tab_02.png?d='+new Date()*1"
          :alt="$t('热门选购')"
          v-show="show !== 1"
        />
        <img
          :src="props.active ? '/img/footer/tab_02_sel.png?d='+new Date()*1 : '/img/footer/tab_02.png?d='+new Date()*1"
          :alt="$t('热门选购')"
          :class="$t('foorter.subscribe')"
          style="height: 4rem"
          v-show="show === 1"
        />
      </template>
    </van-tabbar-item>
    <!-- 中间 -->
    <van-tabbar-item to="/Home">
      <span class="foorterTxt">{{ $t("首页") }}</span>
      <template #icon="props">
        <img class="tui"
          :src="props.active ? '/img/footer/tab_05.png?d='+new Date()*1 : '/img/footer/tab_05.png?d='+new Date()*1"
        />
      </template>
    </van-tabbar-item>
    <!-- 视频 -->
    <van-tabbar-item to="/Notice">
      <span class="foorterTxt">{{ $t("消息") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/tab_03.png?d='+new Date()*1 : '/img/footer/tab_03.png?d='+new Date()*1"
          :alt="$t('消息')"
          v-show="show !== 3"
        />
        <img
          :src="props.active ? '/img/footer/tab_03.png?d='+new Date()*1 : '/img/footer/tab_03.png?d='+new Date()*1"
          :alt="$t('消息')"
          :class="$t('foorter.video')"
          style="height: 4rem"
          v-show="show === 3"
        />
      </template>
    </van-tabbar-item>
    <!-- 我的 -->
    <van-tabbar-item to="/Mine">
      <span class="foorterTxt">{{ $t("foorter.my") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/tab_04_sel.png?d='+new Date()*1 : '/img/footer/tab_04.png?d='+new Date()*1"
          :alt="$t('foorter.my')"
          v-show="show !== 4"
        />
        <img
          :src="props.active ? '/img/footer/tab_04_sel.png?d='+new Date()*1 : '/img/footer/tab_04.png?d='+new Date()*1"
          :alt="$t('foorter.my')"
          :class="$t('foorter.my')"
          style="height: 4rem"
          v-show="show === 4"
        />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      active: 2
    };
  },
  methods: {},
  watch: {
    $route(to) {
      if (to.name == "girls") {
        this.active = 0;
        this.show = true;
      } else if (to.name == "game") {
        this.active = 1;
        this.show = true;
      } else if (to.name == "home") {
        this.active = 2;
        this.show = true;
      } else if (to.name == "Notice") {
        this.active = 3;
        this.show = true;
      } else if (to.name == "mine") {
        this.active = 4;
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
  created() {
    if (this.$route.name == "girls") {
      this.active = 0;
      this.show = true;
    } else if (this.$route.name == "game") {
      this.active = 1;
      this.show = true;
    } else if (this.$route.name == "home") {
      this.active = 2;
      this.show = true;
    } else if (this.$route.name == "Notice") {
      this.active = 3;
      this.show = true;
    } else if (this.$route.name == "mine") {
      this.active = 4;
      this.show = true;
    } else {
      this.show = false;
    }
  },
};
</script>

<style lang="less" scoped>
@tabbar-height: 110px;
@tabbar-img: 50px;
.van-tabbar {
  height: @tabbar-height;
}
.van-tabbar-item__icon img {
  height: @tabbar-img;
}
.van-tabbar-item {
  font-size: 26px;
}
.tui {
  width: 4rem;
  height: 4rem!important;
  margin-top: -9.333vw;
  border-radius: 50%;
  z-index: 10;
}
[class*="van-hairline"]::after {
  border: none !important;
}
.foorterTxt{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  font-size: 0.725rem;
  color: #2c2b29;
}
</style>
